import { createSlice } from '@reduxjs/toolkit';

const initialProductState = {
  products: []
};

const productSlice = createSlice({
  name: 'products',
  initialState: initialProductState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    }
  }
});

export const getProducts = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      var query = `{
        products {
          name
          unit
          defaultPrice
          producer {
            name
          }
        }
      }`;

      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL,
        {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: query })
        }
      );

      const data = await response.json();

      return data.data.products;
    };

    try {
      const productData = await fetchData();
      dispatch(productActions.setProducts(productData));
    } catch {
      throw new Error('Could not fetch data');
    }
  };
};

export const productActions = productSlice.actions;
export default productSlice.reducer;
