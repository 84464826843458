import React from 'react';

function Product(props) {
  return (
    <div className="max-w-xs overflow-hidden bg-white dark:bg-gray-800 border-b-2">
      <div className="relative">
        <img className="object-cover w-full h-48 mt-2" src="https://img.juntalocal.com/images/produtos/10482_5682_1.jpg" alt="Junta Solidária" />
        <button className="absolute text-lg bottom-4 right-4 h-8 w-8 font-bold text-gray-900 transition-colors duration-200 transform bg-yellow rounded-full hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">
          +
        </button>
      </div>
      <div className="py-2 h-24">
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{props.product.producer.name}</p>
        <h1 className="text-lg font-bold text-gray-800 dark:text-white">{props.product.name}</h1>
      </div>
      <div className="flex items-center justify-between py-2 bg-white">
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">{props.product.unit}</p>
        <p className="mt-1 text-lg text-gray-600 dark:text-gray-400">
          R$ {(parseFloat(props.product.defaultPrice) / 100).toFixed(2).replace('.', ',')}
        </p>
      </div>
    </div>
  );
};

export default Product;
