import { createSlice } from '@reduxjs/toolkit';

const initialCategoryState = {
  categories: [{ name: 'Categoria1', categories: [{ name: 'Subcategoria1' }] }]
};

const categorySlice = createSlice({
  name: 'categories',
  initialState: initialCategoryState,
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    }
  }
});

export const getCategories = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      var query = `{
        categories {
          name
          categories {
            name
          }
        }
      }`;

      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL,
        {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ query: query })
        }
      );

      const data = await response.json();

      return data.data.categories;
    };

    try {
      const categoryData = await fetchData();
      dispatch(categoryActions.setCategories(categoryData));
    } catch {
      throw new Error('Could not fetch data');
    }
  };
};

export const categoryActions = categorySlice.actions;
export default categorySlice.reducer;
