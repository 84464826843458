import { configureStore } from '@reduxjs/toolkit';

import categoryReducer from './categories';
import authenticationReducer from './authentication';
import productReducer from './products';

const store = configureStore({
  reducer: { category: categoryReducer, authentication: authenticationReducer, products: productReducer }
});

export default store;
