import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  ChartBarIcon,
  MenuIcon,
  SupportIcon,
  XIcon,
} from '@heroicons/react/outline';

import SearchInput from './SearchInput';
import CategoryMenu from './CategoryMenu';
import CartIcon from './Cart/CartIcon';

const solutions = [
  {
    name: 'Entrar',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  }
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  }
]

const menu_options = [
  { name: 'Ajuda'},
  { name: 'Peça de novo'},
  { name: 'Entrar'},
]

function Header() {
  const [hiddenClass, setHiddenClass] = useState('');

  return (
    <div>
      <Popover className="relative bg-yellow">
        <div className={"mx-auto px-4 sm:px-6 grid grid-cols-12 " + hiddenClass}>
          <div></div>
          <div className="col-span-10 text-center grid justify-items-center">
            <span className="p-2">Frete grátis para pedidos acima de R$300,00</span>
          </div>
          <div className="text-right">
            <button type="button" className="rounded-md p-2 hover:text-gray-500 focus:outline-none" onClick={ () => { setHiddenClass('hidden'); }}>
              <span className="sr-only">Close menu</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </Popover>
      <Popover className="relative bg-white z-10">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6">
              <div className="items-center grid sm:grid-cols-12 xs:grid-cols-3 grid-flow-col sm:grid-flow-row sm:border-b-2 border-gray-100 py-2">
                <div className="sm:col-span-4">
                  <a href="/">
                    <span className="sr-only">Sacola da Junta Local</span>
                    <img
                      className="h-14"
                      src="/logo-junta-local.svg"
                      alt="Logo da Junta Local"
                    />
                  </a>
                </div>
                <div className="hidden sm:block sm:col-span-4 justify-self-center">
                  <SearchInput />
                </div>
                <div className="hidden sm:block items-center sm:col-span-2 lg:col-span-3 justify-self-end sm:grid sm:grid-cols-3">
                  {menu_options.map((item) => (
                    <div key={item.name} className="invisible lg:visible justify-self-center">
                      <a href="/" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 px-4">
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
                <div className="justify-self-end">
                  <CartIcon />
                </div>
                <div className="-mr-2 -my-2 lg:hidden justify-self-end p-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-2 focus:ring-inset focus:ring-yellow">
                    <span className="sr-only">Abrir menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>

              </div>
              <div className="xs:block sm:hidden items-center grid sm:grid-cols-12 xs:grid-cols-3 grid-flow-col sm:grid-flow-row border-b-2 border-gray-100 py-2">
                <div className="justify-self-center">
                  <SearchInput />
                </div>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                static
                className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                  <div className="pt-5 pb-6 px-5">
                    <div className="flex items-center justify-between">
                      <div>
                        <img
                          className="h-10 w-auto"
                          src="/main-logo.svg"
                          alt="Logo da Junta Local"
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                          <span className="sr-only">Fechar menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="mt-6">
                      <nav className="grid gap-y-8">
                        {solutions.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                          >
                            <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                            <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="py-6 px-5 space-y-6">
                    <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                      {resources.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="text-base font-medium text-gray-900 hover:text-gray-700"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div>
                      <a
                        href="/"
                        className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        Cadastre-se
                      </a>
                      <p className="mt-6 text-center text-base font-medium text-gray-500">
                        Já é cadastrado?{' '}
                        <a href="/" className="text-indigo-600 hover:text-indigo-500">
                          Entrar
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      <CategoryMenu/>
    </div>
  );
}

export default Header;
