import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from '../store/products';

import Product from './Product';

function ProductList() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  return (
    <div className="max-w-screen-2xl m-auto grid grid-flow-col grid-rows-2 md:grid-flow-row md:grid-cols-4 auto-cols-max gap-4 p-4 overflow-x-auto col-span-full lg:p-6 lg:grid-cols-5">
      {products.map((item) => (
        <Product key={item.name} product={item} />
      ))}
    </div>
  );
}

export default ProductList;
