import React from 'react';

function CartIcon() {
  return (
    <div className="relative">
      <a href="/">
        <span className="sr-only">Carrinho</span>
        <img
          className="w-auto"
          src="/cart-icon.svg"
          alt="Carrinho"
        />
        <div className="absolute inset-1/4 text-center text-white font-bold">0</div>
      </a>
    </div>
  );
};

export default CartIcon;
