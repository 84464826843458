import React from 'react';

function SearchInput() {
  return (
    <div className="relative">
      <input className="border-2 rounded-md w-80 xl:w-96 h-12 pl-7 focus:outline-none" type="text" placeholder="Pesquisar produtos" />
      <button type="submit" className="absolute right-0 top-0 mt-3 mr-4 focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </button>
    </div>
  );
};

export default SearchInput;
