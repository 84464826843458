const first_column = [
  { name: 'Sobre nós' },
  { name: 'Quem Faz' },
  { name: 'Revista da Junta' },
  { name: 'Feiras' },
  { name: 'Fique por dentro' },
  { name: 'Quero ser produtor' }
]

const second_column = [
  { name: 'Informações da entrega' },
  { name: 'Dúvidas' },
  { name: 'Fale conosco' },
  { name: 'Termos de uso' },
  { name: 'Políticas de privacidade' }
]

function Footer() {
  return (
    <div>
      <footer id="footer" className="relative z-50 pt-24">
        <div className="border-t border-b border-gray-200 py-16 bg-yellow">
          <div className="mx-auto container px-4 xl:px-12 2xl:px-4">
            <div className="lg:flex lg:w-1/2 mb-8 text-xs leading-5">
              <div className="w-full px-6">
                <p>
                  Acreditamos que para comer bem precisamos saber de onde vem a comida e quem são os produtores.
                  Nossas plataformas valorizam o elo humano dentro da cadeia alimentar. Queremos que você conheça melhor quem faz,
                  na feira, no site ou pelo conteúdo da nossa Revista e mídias sociais.
                </p>
              </div>
            </div>
            <div className="lg:flex">
              <div className="w-full lg:w-1/2 mb-16 lg:mb-0 flex">
                <div className="w-full lg:w-1/2 px-6">
                  <h2>Sobre a Junta Local</h2>
                  <ul>
                    {first_column.map((item) => (
                      <li key={item.name} className="mt-2">
                        <a href="/" className="text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-full lg:w-1/2 px-6">
                  <h2>Precisa de ajuda?</h2>
                  <ul>
                    {second_column.map((item) => (
                      <li key={item.name} className="mt-2">
                        <a href="/" className="text-xs lg:text-sm leading-none hover:text-brand dark:hover:text-brand text-gray-800 dark:text-gray-50">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-full lg:w-1/2 flex">
                <div className="lg:w-1/2 lg:px-6"></div>
                <div className="w-full lg:w-1/2 px-6 flex flex-col justify-between">
                  <div className="flex items-center mb-6 grid grid-cols-3">
                    <a href="/">
                      <img
                        className="h-10 m-auto"
                        src="/facebook-logo.png"
                        alt="Logo do Facebook Junta Local"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="h-10 m-auto"
                        src="/instagram-logo.png"
                        alt="Logo do Facebook Junta Local"
                      />
                    </a>
                    <a href="/">
                      <img
                        className="h-10 m-auto"
                        src="/whatsapp-logo.png"
                        alt="Logo do Facebook Junta Local"
                      />
                    </a>
                  </div>
                  <div className="relative">
                    <img
                      className="m-auto"
                      src="/logo-footer.png"
                      alt="Logo da Junta Local"
                    />
                    <p className="mt-8 text-right text-xs">12.255.365/1004-95</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
