import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories } from '../store/categories';

import { Popover, Menu } from '@headlessui/react';

function CategoryMenu() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <Popover className="relative bg-white hidden lg:block shadow z-10">
      <div className="mx-auto py-2 px-4 sm:px-6 flex flex-row flex-wrap justify-between">
        {categories.map((item) => (
          <>
            <div key={item.name} className="px-2 hover-trigger">
              {item.name}
              <Menu as="div" className="ml-3 relative hover-target">
                <>
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                    </Menu.Button>
                  </div>
                  <Menu.Items
                    static
                    className="origin-top-right absolute -right-8 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    {item.categories.map((subcategory) => (
                      <Menu.Item>
                        <a href="/" className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                          {subcategory.name}
                        </a>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </>
              </Menu>
            </div>
          </>
        ))}
      </div>
    </Popover>
  );
};

export default CategoryMenu;
